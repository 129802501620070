import './App.css';
import Issues from './Issues';
import {Switch, Route} from 'react-router-dom';
import One from './Issues/One';
import Two from './Issues/Two';
import Three from './Issues/Three';
import Four from './Issues/Four';
import Five from './Issues/Five';
import Six from './Issues/Six';
import Seven from './Issues/Seven';
import Eight from './Issues/Eight';
import Nine from './Issues/Nine';
import Ten from './Issues/Ten';
import Video from './Video';
import Nav from './Nav';
import About from './About';
import footer from './localImages/footer.png'

function App() {
  return (
    <div className="App">
      {/* <img style={{width: "50%"}} src="https://gdurl.com/s8Nc" alt="HMMMM 1" />
      <div style={{width: "50%"}}>
        <div style={{textOrientation: "mixed", writingMode: "vertical-lr", color: "#98A8EA", fontFamily: "eina01", letterSpacing: "3px"}}>hunty's mostly mathy monthly music zine</div>
      </div> */}
      <Nav />
      <Switch>
        <Route exact path="/">
          <Video />
          <Issues />
        </Route>
        <Route path="/issues">
          <Issues />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/one">
          <One />
        </Route>
        <Route path="/two">
          <Two />
        </Route>
        <Route path="/three">
          <Three />
        </Route>
        <Route path="/four">
          <Four />
        </Route>
        <Route path="/five">
          <Five />
        </Route>
        <Route path="/six">
          <Six />
        </Route>
        <Route path="/seven">
          <Seven />
        </Route>
        <Route path="/eight">
          <Eight />
        </Route>
        <Route path="/nine">
          <Nine />
        </Route>
        <Route path="/ten">
          <Ten />
        </Route>
      </Switch>
      <a href="https://huntysdoodles.com/" target="_blank" rel="noreferrer">
        <img style={{height: "15em"}} src={footer} alt="footer" />
      </a> 
    </div>
  );
}

export default App;
